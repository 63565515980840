@font-face {
  .set-asset-uri-for-path("/fonts/FKRomanStandard-Regular");

  font-family: "FK Roman Standard";
  src: url("@{asset-uri}.eot");
  src:
    url("@{asset-uri}.eot?#iefix") format("embedded-opentype"),
    url("@{asset-uri}.woff2") format("woff2"),
    url("@{asset-uri}.woff") format("woff"),
    url("@{asset-uri}.ttf") format("truetype"),
    url("@{asset-uri}.svg#source_sans_proregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
