@import "shared/extensions";
@import "shared/reset";
@import "shared/variables";
@import "shared/mixins";
@import "shared/material-ui-overrides";
@import "shared/d2-intermediate";
@import "fonts/source-sans-pro";
@import "fonts/montserrat";
@import "fonts/din";
@import "fonts/fk-roman-standard";
@import "fonts/pp-mori";
@import "font-awesome/fontawesome";
@import "vydia-awesome/vydia-awesome";
@import "../../node_modules/react-toggle/style.css";

body {
  background-color: @color-background;
}

.image-gallery-content:not(.fullscreen) .image-gallery-image {
  max-height: 100%;
  text-align: center;

  img {
    width: auto;
    max-width: 100%;
    max-height: 100vh;
    margin: 0 auto;
  }
}
