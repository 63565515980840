@font-face {
  .set-asset-uri-for-path("/fonts/PPMori-Regular");

  font-family: "mori";
  src: url("@{asset-uri}.eot");
  src:
    url("@{asset-uri}.eot?#iefix") format("embedded-opentype"),
    url("@{asset-uri}.woff2") format("woff2"),
    url("@{asset-uri}.woff") format("woff"),
    url("@{asset-uri}.ttf") format("truetype"),
    url("@{asset-uri}.svg#source_sans_proregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  .set-asset-uri-for-path("/fonts/PPMori-Bold");

  font-family: "mori";
  src: url("@{asset-uri}.eot");
  src:
    url("@{asset-uri}.eot?#iefix") format("embedded-opentype"),
    url("@{asset-uri}.woff2") format("woff2"),
    url("@{asset-uri}.woff") format("woff"),
    url("@{asset-uri}.ttf") format("truetype"),
    url("@{asset-uri}.svg#source_sans_proregular") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  .set-asset-uri-for-path("/fonts/PPMori-SemiBold");

  font-family: "mori";
  src: url("@{asset-uri}.eot");
  src:
    url("@{asset-uri}.eot?#iefix") format("embedded-opentype"),
    url("@{asset-uri}.woff2") format("woff2"),
    url("@{asset-uri}.woff") format("woff"),
    url("@{asset-uri}.ttf") format("truetype"),
    url("@{asset-uri}.svg#source_sans_proregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  .set-asset-uri-for-path("/fonts/PPMori-Light");

  font-family: "mori";
  src: url("@{asset-uri}.eot");
  src:
    url("@{asset-uri}.eot?#iefix") format("embedded-opentype"),
    url("@{asset-uri}.woff2") format("woff2"),
    url("@{asset-uri}.woff") format("woff"),
    url("@{asset-uri}.ttf") format("truetype"),
    url("@{asset-uri}.svg#source_sans_proregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
